module.exports = [
    {
        title: 'Laracasts.com',
        organizer: 'Laracasts.com',
        image: 'laracasts.jpg',
        period: '25.08.2016 - Present time',
        topics: ['PHP', 'Laravel', 'PHPUnit', 'Vue', 'MySQL', 'JavaScript', 'Clean Code'],
        url: 'https://laracasts.com/@AngelinCalu',
    },
    {
        title: 'Laravel API Development & Vue JS SPA from Scratch',
        organizer: 'Udemy',
        image: 'udemy.png',
        period: 'January 2021',
        topics: ['JavaScript', 'Vue', 'Laravel', 'PHP', 'TDD'],
        url: 'https://www.udemy.com/certificate/UC-0fdd2d4f-634c-46b7-a26a-b46069e7a031/',
    },
    {
        title: 'Buutcamp Sprint: TypeScript',
        organizer: 'Buutti Oy, Finland',
        image: 'buutti.png',
        period: '27.08.2020 - 17.12.2020',
        topics: [
            'JavaScript',
            'TypeScript',
            'React',
            'ExpressJs',
            'MongoDB',
            'PostgreSQL',
            'Agile / SCRUM methodology',
        ],
        url: 'https://www.buutti.com/',
    },
    {
        title: 'Modern JavaScript for React JS - ES6',
        organizer: 'Udemy',
        image: 'udemy.png',
        period: 'August 2020',
        topics: ['JavaScript'],
        url: 'https://www.udemy.com/certificate/UC-ae82c1a4-57e9-4a69-9286-2ecacf61a671/',
    },
    {
        title: 'JavaScript ES6 Course: ECMA Script 6 (Step by Step)',
        organizer: 'Udemy',
        image: 'udemy.png',
        period: 'August 2020',
        topics: ['JavaScript'],
        url: 'https://www.udemy.com/certificate/UC-09c07f07-3b9f-4917-a25f-52041cc5e0fd/',
    },
    {
        title: 'Interactive Web Animation 2020 [JavaScript, SVG, CSS & HTML]',
        organizer: 'Udemy',
        image: 'udemy.png',
        period: 'December 2020',
        topics: ['CSS animations', 'SVG animations', 'JavaScript animations'],
        url: 'https://www.udemy.com/certificate/UC-6cec81c3-a7d0-400a-9bd0-cf3c04cf5815/',
    },
];
